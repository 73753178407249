<template>
  <ExtendedFilteredProducts
    :products="otherProducts"
    :searchCompleted="searchCompleted"
    v-if="showFilteredProducts && !isLoading"
  />
  <LoadingOverlay
    v-if="isLoading && !$store.getters.loading"
    style="position: relative; background: none; width: 100%; height: 50vh"
    :innerStyle="'position:absolute;'"
  ></LoadingOverlay>

  <div v-if="!isLoading">
    <template v-for="index in 2" :key="index">
      <div class="row align-items-center" v-if="index === 2">
        <div class="col-6">
          <h2 class="view-title">
            {{
              `${$t("title.sold")} - ${
                lines.reduce((accumulator, item) => {
                  return accumulator + item.salda;
                }, 0) ?? 0
              } ${$t("title.products")}`
            }}
          </h2>
        </div>
      </div>
      <div class="row table-header d-flex align-items-center sticky-header-0">
        <div
          class="col d-flex align-items-center"
          v-for="title_key in filter.group"
          :key="title_key"
          :class="topSort.col === title_key ? 'active-sort-label' : ''"
          @click="setSort(topSort, title_key)"
        >
          {{ $t("tableTitle." + title_key) }}
          <img
            src="@/assets/img/skinny-drop-arrow.svg"
            :class="
              topSort.col === title_key && topSort.asc
                ? 'active-sort'
                : 'inactive-sort'
            "
            style=""
          />
        </div>
        <div class="col-2-5"></div>
      </div>
      <div
        class="table-item d-flex align-items-center"
        v-if="TopHeaderSorted.length === 0"
      >
        {{ $t("label.noRows") }}
      </div>

      <template v-for="(value, headerIndex) in TopHeaderSorted" :key="value">
        <template
          v-if="
            checkFilter(value) &&
            value.items.filter(obj => checkFilter(obj, value)).length !== 0 &&
            checkType(value, index)
          "
        >
          <TopHeaderRow
            :key="value"
            :value="value"
            :columns="filter.group"
            :checkType="(value, int) => checkType(value, int)"
            :convertCurrencyCodeToSymbol="() => convertCurrencyCodeToSymbol()"
            :parsePrice="value => parsePrice(value)"
            :countFiltredRows="rows => countFiltredRows(rows)"
            :countReserved="reservedAmount => countReserved(reservedAmount)"
            :getFieldText="
              (supplierCode, key) => getFieldText(supplierCode, key)
            "
            :clickProducts="
              (productIndex, reserved) => clickProducts(productIndex, reserved)
            "
            :clickSoldProducts="
              (soldProductIndex, sold) =>
                clickSoldProducts(soldProductIndex, sold)
            "
            @imgViewer="
              itemsObject => {
                imgViewer = itemsObject;
              }
            "
            :index="index"
            :headerIndex="headerIndex"
            :isExpanded="headerIndex === expanded_index && tableIndex === index"
            :isBlurred="value['missingOnGreenified']"
            :isLoading="value['loadingStatus']"
            ><template #master-button>
              <slot
                name="master-button"
                :value="value"
                :clickShowVariants="clickProducts"
              >
                <button
                  class="btn w-100"
                  style="max-width: 160px"
                  :class="
                    i !== expanded_index ? 'btn-outline-dark' : 'btn-dark'
                  "
                  @click="
                    index === 1
                      ? clickProducts(headerIndex)
                      : clickSoldProducts(headerIndex, value.salda)
                  "
                >
                  {{
                    i !== expanded_index
                      ? $t("button.seeProducts")
                      : $t("button.hideProducts")
                  }}
                </button></slot
              >
            </template>
          </TopHeaderRow>

          <div
            class="row sub-list"
            v-if="headerIndex === expanded_index && tableIndex === index"
          >
            <div class="row table-header d-flex align-items-center">
              <div class="col-0-5 d-flex"></div>

              <div
                class="col d-flex justify-content-between align-items-center"
                v-for="title_key in headers.filter(
                  r => !filter.group.includes(r),
                )"
                :key="title_key"
                @click="setSort(bottomSort, title_key)"
              >
                <div>
                  {{ $t("tableTitle." + title_key) }}
                  <img
                    src="@/assets/img/skinny-drop-arrow.svg"
                    :class="
                      bottomSort.col === title_key && bottomSort.asc
                        ? 'active-sort'
                        : 'inactive-sort'
                    "
                  />
                </div>
              </div>
              <div class="col-2 d-flex align-items-center justify-content-end">
                <p class="me-2" v-if="index === 2">
                  {{ $t("label.markSold") }} ({{ countSoldProducts }}/{{
                    value.salda
                  }})
                </p>
                <input
                  v-if="index === 2"
                  type="checkbox"
                  class="small-checkbox"
                  :checked="value.salda === countSoldProducts"
                  :disabled="
                    countPrevSoldProducts === value.salda &&
                    value.salda === countSoldProducts
                  "
                  @change="
                    inputValue => {
                      markAllAsSold(inputValue, value);
                    }
                  "
                  @input="isEditingSold = true"
                />
              </div>
            </div>
            <template v-for="item in BottomHeaderSorted" :key="item">
              <div
                class="row table-item d-flex align-items-center"
                v-if="checkFilter(item, value)"
              >
                <div class="col-0-5 d-flex"></div>

                <div
                  class="col"
                  v-for="title_key in headers.filter(
                    r => !filter.group.includes(r),
                  )"
                  :key="title_key"
                >
                  <template v-if="title_key === 'benamn'">
                    <div style="width: 56px; margin-right: 10px">
                      <div class="product-image">
                        <img
                          @click="imgViewer = item.images"
                          v-lazy="item.images?.bild_1?.url"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <strong>{{ item[title_key] }}</strong>
                    </div>
                  </template>

                  <template v-else-if="title_key === 'skador'">
                    {{ getFieldText(item[title_key], title_key) }}

                    <div class="mt-1">
                      <template
                        v-for="(value, key, i) in item.unique_images"
                        :key="value"
                      >
                        <a :href="content + value.hash" target="_blank"
                          >{{ $t("label.image") }}{{ i + 1 }} </a
                        >{{
                          i < Object.keys(item.unique_images).length - 1
                            ? ","
                            : null
                        }}&nbsp;</template
                      >
                    </div>
                  </template>
                  <p
                    v-else-if="title_key === 'matt'"
                    v-html="getTranslatedMeasures(item)"
                  ></p>
                  <p
                    v-else-if="title_key === 'recondition'"
                    v-html="getTranslatedSubActions(item)"
                  ></p>
                  <template v-else-if="title_key === 'varumarke'">{{
                    getFieldText(
                      value[title_key] ?? $t("levkod." + value["levkod"]),
                      title_key,
                    )
                  }}</template>

                  <template v-else>{{
                    getFieldText(item[title_key], title_key)
                  }}</template>
                </div>
                <div
                  class="col-2 d-flex justify-content-end align-items-center"
                >
                  <InformationTooltip
                    class="me-1"
                    :tooltipIcon="historyIcon"
                    altText="History icon"
                    :size="'large'"
                    :infoText="$t('title.productHistory')"
                    offset="0"
                    @click="
                      showProductHistory = true;
                      selectedItemId = item.id;
                    "
                  />
                  <div
                    class="d-flex justify-content-end align-items-center"
                    v-if="index === 2"
                  >
                    <p
                      class="me-2 fst-italic"
                      v-if="markedAsSold[item.id] === '1'"
                    >
                      {{ $t("label.sold") }}
                    </p>
                    <input
                      type="checkbox"
                      class="small-checkbox"
                      :true-value="'1'"
                      :false-value="'0'"
                      :disabled="
                        value.salda === countSoldProducts &&
                        markedAsSold[item.id] !== '1'
                      "
                      v-model="markedAsSold[item.id]"
                      @input="isEditingSold = true"
                      @change="
                        inputValue => {
                          item.isMarked = inputValue.target.checked;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-for="item in value.items" :key="item">
              <div
                class="row table-item d-flex align-items-center defiltred"
                v-if="!checkFilter(item, value)"
              >
                <div class="col-0-5 d-flex"></div>
                <div
                  class="col"
                  v-for="title_key in headers.filter(
                    r => !filter.group.includes(r),
                  )"
                  :key="title_key"
                >
                  <template v-if="title_key === 'benamn'">
                    <div style="width: 56px; margin-right: 10px">
                      <div class="product-image">
                        <img
                          @click="imgViewer = item.images"
                          v-lazy="item.images?.bild_1?.url"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <strong>{{ item[title_key] }}</strong>
                    </div>
                  </template>
                  <template v-else-if="title_key === 'skador'">
                    {{ getFieldText(item[title_key], title_key) }}

                    <div class="mt-1">
                      <template
                        v-for="(value, key, i) in item.unique_images"
                        :key="value"
                      >
                        <a :href="content + value.hash" target="_blank"
                          >{{ $t("label.image") }}{{ i + 1 }} </a
                        >{{
                          i < Object.keys(item.unique_images).length - 1
                            ? ","
                            : null
                        }}&nbsp;</template
                      >
                    </div>
                  </template>
                  <p
                    v-else-if="title_key === 'matt'"
                    v-html="getTranslatedMeasures(item)"
                  ></p>
                  <p
                    v-else-if="title_key === 'recondition'"
                    v-html="getTranslatedSubActions(item)"
                  ></p>

                  <template v-else-if="title_key === 'varumarke'">{{
                    getFieldText(
                      value[title_key] ?? $t("levkod." + value["levkod"]),
                      title_key,
                    )
                  }}</template>

                  <template v-else>{{
                    getFieldText(item[title_key], title_key)
                  }}</template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>

      <div class="row" v-if="index === 2">
        <div class="col-12 d-flex justify-content-end">
          <h2 class="view-title">
            {{ $t("title.totalSales") }}:
            {{ countSoldTotal.replace(/,/g, " ") }}
            {{ convertCurrencyCodeToSymbol() }}
          </h2>
        </div>
      </div>
    </template>
  </div>
  <HistoryModal
    v-if="showProductHistory"
    :itemId="selectedItemId"
    @close="showProductHistory = false"
  />
  <GroupDetails
    :showDeleteButton="false"
    v-if="imgViewer"
    @update="
      imgViewer = null;
      getLines();
    "
    @close="imgViewer = null"
    :item="imgViewer"
  ></GroupDetails>
  <InventoryFooter v-if="isEditingSold" :leftSpace="false">
    <div class="row w-100 align-items-center">
      <div class="col-6 d-flex align-items-center fw-bolder">
        {{ $t("label.markedAsSold") }} {{ countSoldProducts }} /
        {{ soldAmount }}
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <button
          class="btn btn-dark"
          @click="
            () => {
              verifyUpdateSold = true;
            }
          "
        >
          {{ $t("button.updateSoldProducts") }}
        </button>
      </div>
    </div>
  </InventoryFooter>
  <VerificationModal
    v-if="verifyUpdateSold"
    @close="verifyUpdateSold = false"
    @confirm="
      updateSoldProducts();
      this.isEditingSold = false;
    "
    :title="$t('title.updateSoldProducts')"
    :text="$t('paragraph.verifyUpdateSoldStatus')"
  />

  <ResponseModalSimple
    v-if="showUpdateSuccess"
    :title="$t('title.updateSuccessful')"
    :responseText="$t('paragraph.soldStatusIsUpdated')"
    @close="
      showUpdateSuccess = false;
      getLines();
    "
  />
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import {
  getTranslatedMeasures,
  getTranslatedSubActions,
} from "@/modules/utilities.js";
import { mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";
import GroupDetails from "@/components/GroupDetails.vue";
import LoadingOverlay from "./LoadingOverlay.vue";
import VerificationModal from "@/components/modal/VerificationModal";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple";
import InventoryFooter from "@/components/InventoryFooter";
import ExtendedFilteredProducts from "./ExtendedFilteredProducts.vue";
import TopHeaderRow from "./TopHeaderRow.vue";
import itemAdministration from "@/services/api/itemAdministration";
import router from "@/router";
import InformationTooltip from "./InformationTooltip.vue";
import HistoryIcon from "../assets/img/history-thin.svg";
import HistoryModal from "../components/modal/HistoryModal";

export default {
  name: "ProjectAction",
  props: {
    headers: {
      type: Array,
      default: () => [
        "benamn",
        "comp_id",
        "besk",
        "kategori_id",
        "levkod",
        "skick2",
        "rum",
        "skador",
        "matt",
      ],
    },
    filter: {
      type: Object,
      default: () => ({
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        intervals: {},
        status: ["J"],
      }),
    },
    inSort: {
      type: Object,
      default: () => ({ col: "updated_at", asc: false }),
    },
    inChecked: { type: Array, default: () => [] },
  },
  components: {
    GroupDetails,
    LoadingOverlay,
    VerificationModal,
    ResponseModalSimple,
    InventoryFooter,
    ExtendedFilteredProducts,
    TopHeaderRow,
    InformationTooltip,
    HistoryModal,
  },
  data() {
    return {
      selectedItemId: null,
      showProductHistory: false,
      historyIcon: HistoryIcon,
      projectnr: null,
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      imgViewer: null,
      isLoading: true,
      expanded_index: null,
      topSort: { col: null, asc: true },
      bottomSort: { col: null, asc: true },
      lines: [],
      checked: [],
      markedAsSold: {},
      verifyUpdateSold: false,
      isEditingSold: false,
      showUpdateSuccess: false,
      soldAmount: 0,
      showFilteredProducts: false,
      searchCompleted: false,
      tableIndex: null,
      changedProject: false,
    };
  },
  mounted() {
    this.topSort = this.inSort;
    this.checked = this.inChecked;
    this.projectnr = this.getProject();
    this.getLines();
  },

  computed: {
    countSoldTotal() {
      let amount = 0;
      this.lines.forEach(product => {
        if (product.salda > 0) {
          let productTotal = product.salda * Number(product.pris);
          amount += productTotal;
        }
      });
      return amount.toLocaleString("en", { useGrouping: true });
    },
    countPrevSoldProducts() {
      let amount = 0;
      this.BottomHeaderSorted.forEach(product => {
        if (product.sold === "1") amount += 1;
      });
      return amount;
    },
    countSoldProducts() {
      let amount = 0;
      for (const key in this.markedAsSold) {
        if (this.markedAsSold[key] === "1") {
          amount += 1;
        }
      }
      return amount;
    },
    content() {
      return process.env.VUE_APP_CONTENT_URL;
    },
    currency() {
      return this.$store.getters.projectDetails.valuta;
    },
    projectListener() {
      return this.$store.state.project;
    },

    topSortArray: function () {
      if (this.topSort.col === "quantity") {
        return Object.keys(this.lines).sort((a, b) => {
          if (!this.topSort.asc) {
            return this.lines[b].items.length - this.lines[a].items.length;
          } else return this.lines[a].items.length - this.lines[b].items.length;
        });
      }
      return Object.keys(this.lines).sort((a, b) => {
        const valueA = this.lines[a][this.topSort.col];
        const valueB = this.lines[b][this.topSort.col];

        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;
        if (!this.topSort.asc) {
          return valueB.localeCompare(valueA);
        } else {
          return valueA.localeCompare(valueB);
        }
      });
    },

    TopHeaderSorted: function () {
      return this.topSortArray.map(obj => {
        return this.lines[obj];
      });
    },

    BottomHeaderSorted: function () {
      let list =
        this.lines[this.topSortArray[this.expanded_index]]?.items ?? null;
      if (this.bottomSort.col === "comp_id") {
        if (this.bottomSort.asc) {
          return list.sort((a, b) => {
            return b[this.bottomSort.col] - a[this.bottomSort.col];
          });
        }
        return list.sort((a, b) => {
          return a[this.bottomSort.col] - b[this.bottomSort.col];
        });
      }
      if (this.bottomSort.as) {
        return list.sort((a, b) => {
          return b[this.bottomSort.col]?.localeCompare(a[this.bottomSort.col]);
        });
      }
      return list.sort((a, b) => {
        return a[this.bottomSort.col]?.localeCompare(b[this.bottomSort.col]);
      });
    },
  },

  methods: {
    countReserved(reserved) {
      let reservedAmount = 0;
      reserved.forEach(reservedItem => {
        reservedAmount += Number(reservedItem.qty);
      });
      return reservedAmount;
    },

    markAllAsSold(inputValue, itemList) {
      if (this.countSoldProducts !== this.soldAmount) {
        for (let i = 0; i < this.BottomHeaderSorted.length; i++) {
          let product = this.BottomHeaderSorted[i];
          if (this.checkFilter(product, itemList)) {
            if (
              product.sold === "0" ||
              (product.sold === "1" && this.markedAsSold[product.id] === "0")
            ) {
              this.markedAsSold[product.id] = inputValue.target.checked
                ? "1"
                : "0";
            }

            if (this.countSoldProducts === this.soldAmount) break;
          }
        }
      } else {
        this.markedAsSold = {};
        this.BottomHeaderSorted.forEach(product => {
          if (product.sold === "1" && this.checkFilter(product, itemList)) {
            this.markedAsSold[product.id] = "1";
          }
        });
      }
    },
    updateSoldProducts() {
      ItemAdministration.updateSoldProducts(this.projectnr, this.markedAsSold)
        .then(() => {
          this.showUpdateSuccess = true;
          this.verifyUpdateSold = false;
        })
        .catch(error => {
          this.$httpError(error, "GreenifiedList - getGreenifiedProducts");
          this.isLoading = false;
        });
    },
    getFieldText(value, key) {
      if (this.translateHeaders.includes(key) && value) {
        if (key === "levkod" && value.substring(0, 2) === "ex") {
          return this.$t("label.unkownSupplier");
        }
        return this.$t(key + "." + value);
      }
      return value;
    },
    parsePrice(amount) {
      return parseInt(amount).toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },
    convertCurrencyCodeToSymbol(
      currencyCode = this.currency,
      locale = this.$i18n.locale,
    ) {
      const numberFormat = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
      });

      const parts = numberFormat.formatToParts(1);
      let currencySymbol = currencyCode;

      for (const part of parts) {
        if (part.type === "currency") {
          currencySymbol = part.value;
          break;
        }
      }

      return currencySymbol;
    },
    countFiltredRows(value) {
      return value.items.filter(obj => this.checkFilter(obj, value)).length;
    },

    setSort(sort, key) {
      if (sort.col === key) {
        sort.asc = !sort.asc;
      } else {
        sort.col = key;
        sort.asc = true;
      }
    },
    checkType(item, index) {
      if (
        index === 1 &&
        (0 < item.quantity ||
          item["loading"] ||
          (item.quantity === 0 && item.salda === 0) ||
          (!item["permalink"] && !item["loading"]))
      ) {
        return true;
      }
      if (index === 2 && item.salda > 0) {
        return true;
      }
      return false;
    },

    clickProducts(i) {
      if (i === this.expanded_index && this.tableIndex === 1) {
        this.expanded_index = null;
        this.tableIndex = null;
      } else {
        this.expanded_index = i;
        this.tableIndex = 1;
      }
    },

    clickSoldProducts(i, soldAmount) {
      this.soldAmount = soldAmount;
      if (i === this.expanded_index && this.tableIndex === 2) {
        this.isEditingSold = false;
        this.expanded_index = null;
        this.tableIndex = null;
      } else {
        this.markedAsSold = {};
        this.isEditingSold = false;
        this.expanded_index = i;
        this.tableIndex = 2;
        const products =
          this.lines[this.topSortArray[this.expanded_index]]?.items;
        products.forEach(product => {
          if (product.sold === "1") {
            this.markedAsSold[product.id] = "1";
          }
        });
      }
    },

    checkGroupFilter(filter, item, secondObj) {
      if (filter[0].key in item) {
        for (let obj of filter) {
          if (
            (obj.key in item && item[obj.key] === obj.value) ||
            (obj.key === "levkod" &&
              obj.value === "ex" &&
              item[obj.key].substring(0, 2) === "ex")
          ) {
            return true;
          }
        }
        return false;
      }
      if (filter[0].key === "search" && secondObj) {
        for (let obj of filter) {
          const itemCopy = { ...item };
          const secondObjCopy = { ...secondObj };
          for (let key in Object.assign(itemCopy, secondObjCopy)) {
            if (this.translateHeaders.includes(key)) {
              if (
                this.$t(key + "." + itemCopy[key])
                  .toLowerCase()
                  .indexOf(obj.value.toLowerCase()) != -1
              ) {
                return true;
              }
            }
            if (
              typeof itemCopy[key] === "string" &&
              itemCopy[key].toLowerCase().indexOf(obj.value.toLowerCase()) !==
                -1
            ) {
              return true;
            }
          }
        }
        return false;
      }

      return true;
    },
    checkFilter(item, secondObj) {
      const groupFilter = this.filter.filter;
      for (const key in groupFilter) {
        const filterValue = groupFilter[key];
        if (!this.checkGroupFilter(filterValue, item, secondObj)) {
          return false;
        }
      }

      for (const key in this.filter.intervals) {
        const obj = this.filter.intervals[key];
        if (key in item) {
          if ("from" in obj && !(obj.from <= item[key])) {
            return false;
          }
          if ("to" in obj && !(obj.to >= item[key])) {
            return false;
          }
        }
      }

      return true;
    },
    ...mapGetters({
      getProject: "project",
    }),

    getTranslatedMeasures(item) {
      return getTranslatedMeasures(item, this.$i18n);
    },
    getTranslatedSubActions(item) {
      return getTranslatedSubActions(item, this.$i18n);
    },

    getLines: function () {
      this.isLoading = true;

      ItemAdministration.getGreenifiedProducts(this.projectnr)
        .then(response => {
          this.lines = response.data;
          this.isLoading = false;

          this.lines.map(async product => {
            if (product.missingOnGreenified) {
              product.loadingStatus = true;

              await itemAdministration
                .getGreenifiedData(this.projectnr, product)
                .then(response => {
                  Object.assign(product, response.data);
                  product.loadingStatus = false;
                });
            }
            if (product.quantity === 0 && product.salda === 0) {
              product.missingOnGreenified = true;
            }
          });
          this.checked = [];
          this.expanded_index = null;
        })
        .catch(error => {
          if (error.response.data.message.includes("ElasticSearchError")) {
            return router.push({ name: "home" });
          }
          this.$httpError(error, "GreenifiedList - getGreenifiedProducts");
          this.isLoading = false;
        });
    },
  },
  watch: {
    filter: {
      handler(updatedFilter) {
        if (Object.keys(updatedFilter.filter).length) {
          const searchFilters = {};

          for (const filterKey in updatedFilter.filter) {
            updatedFilter.filter[filterKey].forEach(fKey => {
              const searchKey = fKey.key === "hit_on" ? "search" : fKey.key;
              if (Object.keys(searchFilters).includes(searchKey)) {
                searchFilters[searchKey].push(fKey.value);
              } else {
                searchFilters[searchKey] = [fKey.value];
              }
            });
          }

          this.otherProducts = null;
          this.searchCompleted = false;
          this.showFilteredProducts = true;
          ItemAdministration.searchAllProducts(
            this.$store.getters.project,
            searchFilters,
            this.$i18n.locale,
            this.filter.status,
          ).then(res => {
            this.otherProducts = res.data;
            this.searchCompleted = true;
          });
        } else {
          this.otherProducts = null;
          this.showFilteredProducts = false;
          this.searchCompleted = false;
        }
        this.checked = [];
      },
      deep: true,
    },
    expanded_index() {
      this.checked = [];
    },

    topSort: {
      handler() {
        this.expanded_index = null;
      },
      deep: true,
    },

    inSort(newSort) {
      this.topSort = newSort;
    },

    projectListener: function (newVal, oldVal) {
      if (newVal.projekt !== oldVal.projekt) {
        this.projectnr = this.getProject();
        this.changedProject = true;
        this.getLines();
      }
    },
    checked: {
      handler(newValue) {
        this.$emit("update:inChecked", newValue);
      },
      deep: true,
    },
  },
  emits: ["update:inChecked"],
};
</script>
<style scoped>
.row {
  --bs-gutter-x: 0rem;
}
</style>
